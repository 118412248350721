import React from 'react';
import classes from './StateCard.module.css';

const stateCard = (props) => {
    return (
        <div className={classes.StateCard} style={{top: props.top, left: props.left}}>
            <h4 className={classes.Field}>{props.stateName}</h4>
            <p className={classes.Field}>{`Notifications: ${props.notificationCount}`}</p>
            <p className={classes.Field}>{`Registrations: ${props.registrationCount}`}</p>
        </div>
    );
}

export default stateCard;