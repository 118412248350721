import React, {Component} from 'react';
import classes from './MobileKey.module.css';

class MobileKey extends Component {

    state = {
        keyButtonPressed: false
    }

    buttonPressed = (isPressed) => {
        this.setState({
            keyButtonPressed: isPressed
        });
    }

    generateLegendComponents = () => {
        // Set size and placement configurations
        const boxWidth = 180;
        const boxHeight = 140;
        const x = window.innerWidth/2 - boxWidth/2;
        const y = window.innerHeight/2 - boxHeight/2;
        const offsetX = 20; // Shift in X direction
        const offsetY = 25; // Multiplier in the Y direction
        const offsetTextX = 25; // Shift in X direction
        const offsetTextY = 12; // Shift in Y direction
        // Create the outer box
        let legendBox = <rect key='legendbox' className={classes.Legend} x={x} y={y} width='180' height='180' rx='15' ry='15' />;
        // Create the VINE 2 components
        let vineStdSymbol = <rect key='vssym' className={classes.LegendVine2Symbol} x={x+offsetX} y={y+offsetY} width='20' height='20' />;
        let vineStdText = <text key='vstxt' x={x+offsetX+offsetTextX} y={y+offsetY+offsetTextY} dominantBaseline='middle'>Standard VINE</text>;
        // Create the VINE 3 components
        let vine3Symbol = <rect key='v3sym' className={classes.LegendVine3Symbol} x={x+offsetX} y={y+2*offsetY} width='20' height='20' />;
        let vine3Text = <text key='v3txt' x={x+offsetX+offsetTextX} y={y+2*offsetY+offsetTextY} dominantBaseline='middle'>Enhanced VINE</text>;
        // Create the Disabled components
        let disabledSymbol = <rect key='dissym' className={classes.LegendDisabled} x={x+offsetX} y={y+3*offsetY} width='20' height='20' />;
        let disabledText = <text key='distxt' x={x+offsetX+offsetTextX} y={y+3*offsetY+offsetTextY} dominantBaseline='middle'>No VINE Service</text>;
        // Create the Registration components
        let registrationSymbol = <circle key='regsym' className={classes.LegendRegistrationSymbol} cx={x+offsetX+10} cy={y+4*offsetY+10} r='9' />;
        let registrationText = <text key='regtxt' x={x+offsetX+offsetTextX} y={y+4*offsetY+offsetTextY} dominantBaseline='middle'>Registration</text>
        // Create the Notification components
        let notificationSymbol = <circle key='notsym' className={classes.LegendNotificationSymbol} cx={x+offsetX+10} cy={y+5*offsetY+10} r='9' />;
        let notificationText = <text key='nottxt' x={x+offsetX+offsetTextX} y={y+5*offsetY+offsetTextY} dominantBaseline='middle'>Notification</text>;
        // Combine into a render-able array and return
        return [legendBox, vineStdSymbol, vineStdText, vine3Symbol, vine3Text, disabledSymbol, disabledText, registrationSymbol, registrationText, notificationSymbol, notificationText];
    }

    generateLegend = () => {
        return (
            <svg className={classes.LegendCanvas} width={window.innerWidth} height={window.innerHeight}>
                {this.generateLegendComponents()}
            </svg>
        );
    }


    render() {
        let key = (this.state.keyButtonPressed) ? this.generateLegend() : null;
        return (
            <div>
                {key}
                <div
                    className={'fixed-bottom '+classes.MobileKey}
                    style={{left: window.innerWidth-60+'px'}}
                    onTouchStart={() => this.buttonPressed(true)}
                    onTouchEnd={() => this.buttonPressed(false)}
                    onMouseDown={() => this.buttonPressed(true)}                    
                    onMouseUp={() => this.buttonPressed(false)}>
                    <div className={classes.CircleButton}><i className={'fa fa-key'} /></div>
                </div>
            </div>
        );
    }
}

export default MobileKey;