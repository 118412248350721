import React from 'react';
import classes from './Masthead.module.css';
import VineLogo from '../../assets/VINEMAP.svg';
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}
const masthead = (props) => {
    return (
        <div className={'container-fluid '+classes.Masthead} onClick={() => props.history.push('/')}>
            <div className={window.innerWidth > 500 ? 'row' : 'row-override'}>
                    <img className={classes.Logo} src={VineLogo} alt='VINE' />
            </div>
        </div>
    );
}

export default withRouter(masthead);
