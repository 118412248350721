import React, { Component } from 'react';
import classes from './StateMap.module.css';
/* D3 */
import * as d3 from 'd3';
import * as d3Projections from 'd3-composite-projections';
import statesGeoJson from '../../assets/us-states.json';
import countiesGeoJson from '../../assets/us-counties.json';
/* WebSocket */
import socketIOClient from 'socket.io-client';
/* Config Files */
import stateScale from '../../assets/stateScaleConfig.json';
import * as vine3Config from '../../assets/vine3Config.json';
/* Components */
import ActivityDisplay from '../ActivityDisplay/ActivityDisplay';
import Blip from '../Blip/Blip';
import MobileKey from '../MobileKey/MobileKey';
import Ribbon from '../Ribbon/Ribbon';
import StateCard from '../StateCard/StateCard';
import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigate={useNavigate()} location={useLocation()}/>;
}

class StateMap extends Component {
    constructor(props) {
        super(props);
        this.scrolled = this.scrolled.bind(this);
    }

    state = {
        loaded: false,
        width: 0,
        height: 0,
        blips: [],
        notificationCount: 0,
        registrationCount: 0,

        mouseOverCounty: false,
        countyMousedOverName: '',
        mouseX: 0,
        mouseY: 0,
        countyNotificationTotals: null,
        countyRegistrationTotals: null,
        centroid: []
    }

    mainCentroid = [];
    mainX = 0;
    mainY = 0;
    mainK = 0;

    vine3Config = require('../../assets/vine3Config.json');

    stateIdToPostal = {
        "01": "al", "02": "ak", "04": "az", "05": "ar", "06": "ca",
        "08": "co", "09": "ct", "10": "de", "11": "dc", "12": "fl",
        "13": "ga", "15": "hi", "16": "id", "17": "il", "18": "in",
        "19": "ia", "20": "ks", "21": "ky", "22": "la", "23": "me",
        "24": "md", "25": "ma", "26": "mi", "27": "mn", "28": "ms",
        "29": "mo", "30": "mt", "31": "ne", "32": "nv", "33": "nh",
        "34": "nj", "35": "nm", "36": "ny", "37": "nc", "38": "nd",
        "39": "oh", "40": "ok", "41": "or", "42": "pa", "44": "ri",
        "45": "sc", "46": "sd", "47": "tn", "48": "tx", "49": "ut",
        "50": "vt", "51": "va", "53": "wa", "54": "wv", "55": "wi",
        "56": "wy", "66": "gu", "72": "pr"
    }

    /*
        input:
            southCaronlinaCentroid: South Carolina's centroid [X,Y] w.r.t. current screen dimensions
        output:
            An array of SVG JSX elements to be rendered.  Assumes they will be enclosed later within an <svg></svg> tag.
        description:
            Takes in South Carolina's centroid.  Then creates the necessary <rect /> and <text /> elements to make
            a legend on a SVG canvas.  Legend will be shifted to the right of South Carolina. Returns an array
            of svg jsx elements ready to be drawn.
    */
            generateLegendComponents = (southCarolinaCentroid) => {
                // Set size and placement configurations
                const x = southCarolinaCentroid[0];
                const y = southCarolinaCentroid[1]-100;
                const offsetX = 10; // Shift in X direction
                const offsetY = 25; // Multiplier in the Y direction
                const offsetTextX = 23; // Shift in X direction
                const offsetTextY = 12; // Shift in Y direction
                const iconHight = 20;
                // Create the outer box
                let legendBox = <rect key='legendbox' className={classes.Legend} x={x} y={y+10}
                    width='230' height='150' rx='15' ry='15' />;
                // Create the deployed icon components
                let deployedSymbol = <rect key='deploysym' className={classes.deployedState}
                    x={x+offsetX} y={y+offsetY} width='20' height={iconHight} />;
                let deployedText = <text key='deploytxt' x={x+offsetX+offsetTextX} y={y+offsetY+offsetTextY}
                    dominantBaseline='middle'>Deployed</text>;
                // Create the partdeployed components
                let partdeployedSymbol = <rect key='partdeploysym' fill="url(#deployHatch)"
                    className={classes.partialState}
                    x={x+offsetX} y={y+2*offsetY} width='20' height={iconHight} />;
                let partdeployedText = <text key='partdeploytxt' x={x+offsetX+offsetTextX} y={y+2*offsetY+offsetTextY}
                    dominantBaseline='middle'>Deployed in some areas</text>;
                // Create the Disabled components
                let disabledSymbol = <rect key='disabledsym' className={classes.nocontractState}
                    x={x+offsetX} y={y+3*offsetY} width='20' height={iconHight} />;
                let disabledText = <text key='disabledtxt' x={x+offsetX+offsetTextX} y={y+3*offsetY+offsetTextY}
                    dominantBaseline='middle'>Pending Contract</text>;

                // Create the Registration components
                let registrationSymbol = <circle key='regsym' className={classes.LegendRegistrationSymbol}
                    cx={x+offsetX+10} cy={y+4*offsetY+10} r='9' />;
                let registrationText = <text key='regtxt' x={x+offsetX+offsetTextX} y={y+4*offsetY+offsetTextY}
                    dominantBaseline='middle'>Registration</text>

                // Create the Notification components
                let notificationSymbol = <circle key='notsym' className={classes.LegendNotificationSymbol}
                    cx={x+offsetX+10} cy={y+5*offsetY+10} r='9' />;
                let notificationText = <text key='nottxt' x={x+offsetX+offsetTextX} y={y+5*offsetY+offsetTextY}
                    dominantBaseline='middle'>Notification</text>;
                // Combine into a render-able array and return
                return [legendBox, deployedSymbol, deployedText, partdeployedSymbol, partdeployedText,
                    disabledSymbol, disabledText, registrationSymbol, registrationText, notificationSymbol, notificationText];
            }

    _onMouseMove = (event) => {
        this.setState({ mouseX: event.pageX, mouseY: event.pageY });
    }

    updateWidthHeight = () => {
        this.setState({
            loaded: false,
            blips: [],
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    incrementNotificationCount = () => {
        this.setState({ notificationCount: this.state.notificationCount+1 });
    }

    incrementRegistrationCount = () => {
        this.setState({ registrationCount: this.state.registrationCount+1 });
    }

    componentDidMount() {
        // Update the dimensions of the window
        this.updateWidthHeight();
        // Listen for future resize events
        window.addEventListener('resize', this.updateWidthHeight);

        // Setup the socket connection
        const socket = socketIOClient(process.env.REACT_APP_EVENTS_URL+`?key=${this.props.params.statePostal}`);
        // Handle notifications
        socket.on(`events_${this.props.params.statePostal}`, msg => {
            const width = this.state.width;
            const height = this.state.height;
            const projection = d3Projections.geoAlbersUsaTerritories()
                .translate([width/2, height/2])
                .scale([width]);
            const path = d3.geoPath().projection(projection);
            // TRANSFORM CALCULATIONS
            let centroid = null;
            statesGeoJson.features.forEach(element => {
                if(element.properties.postal === this.props.params.statePostal) {
                    centroid = path.centroid(element);
                    this.setState({centroid: centroid});
                }
            });
            const x = centroid[0];
            const y = centroid[1];
            const k = stateScale[''+this.props.params.statePostal];
            const zoom = d3.zoom()
                    .scaleExtent([k, k+5])
                    .on('zoom', this.scrolled);
            d3.selectAll('svg').call(zoom);
            const messageJson = JSON.parse(msg);
            // Get Blips
            let notificationBlips = messageJson.notifications.events.map(event => {
                return <Blip
                            key={event.sid}
                            x={projection([+event.longitude, +event.latitude])[0]}
                            y={projection([+event.longitude, +event.latitude])[1]}
                            isNotification={true}
                            incrementer={this.incrementNotificationCount}
                            customStrokeWidth={""+2/k}
                            transform={{
                                translateX1: this.state.width/2,
                                translateY1: this.state.height/2,
                                scale: k,
                                translateX2: -x,
                                translateY2: -y
                            }}
                            canvasWidth={this.state.width}
                            stateConstant={k}
                        />;
            });

            let registrationBlips = messageJson.registrations.events.map(event => {
                return <Blip
                            key={event.sid}
                            x={projection([+event.longitude, +event.latitude])[0]}
                            y={projection([+event.longitude, +event.latitude])[1]}
                            isNotification={false}
                            incrementer={this.incrementRegistrationCount}
                            transform={{
                                translateX1: this.state.width/2,
                                translateY1: this.state.height/2,
                                scale: k,
                                translateX2: -x,
                                translateY2: -y
                            }}
                            canvasWidth={this.state.width}
                            stateConstant={k}
                        />;
            })

            this.setState({
                loaded: true,
                blips: notificationBlips.concat(registrationBlips),
                notificationCount: messageJson.notifications.totals.last,
                registrationCount: messageJson.registrations.totals.last,
                countyNotificationTotals: messageJson.notifications.totals,
                countyRegistrationTotals: messageJson.registrations.totals
            });
        });
    }

    componentWillUnmount() {
        // Remove the window listener if we don't need it anymore
        window.removeEventListener('resize', this.updateWidthHeight);
    }

    render() {
        // Get the current screen width and height
        const width = this.state.width;
        const height = this.state.height;

        // Create the map projection function
        const projection = d3Projections.geoAlbersUsaTerritories()
            .translate([width/2, height/2])
            .scale([width]);

        // Create the path function using our projection
        const path = d3.geoPath()
            .projection(projection);

        // Get Transformation Information
        statesGeoJson.features.forEach( stateFeature => {
            if(stateFeature.properties.postal === this.props.params.statePostal) {
                this.mainCentroid = path.centroid(stateFeature);
                this.mainX = this.mainCentroid[0];
                this.mainY = this.mainCentroid[1];
                this.mainK = stateScale[''+this.props.params.statePostal];
            }
        });

        // Convert statesGeoJson to svg paths for each state
        const states = statesGeoJson.features.map(stateFeature => {
            const isSelected = (stateFeature.properties.postal === this.props.params.statePostal);
            const d = path(stateFeature.geometry);
            return isSelected ? null :
                    <path
                        key={stateFeature.properties.postal}
                        className={classes.State}
                        d={d}
                        style={{strokeWidth: ""+1/this.mainK}}
                        transform={`translate(${this.state.width/2}, ${this.state.height/2})
                                    scale(${this.mainK})
                                    translate(${-this.mainX}, ${-this.mainY})`}
                    />;
        });

        // Convert countiesGeoJson to svg paths for each county
        const counties = countiesGeoJson.features.map(countyFeature => {
            const isSelected = (this.stateIdToPostal[countyFeature.properties.STATE] === this.props.params.statePostal);
            let className = null;
            let deploymentStatus = vine3Config.default[this.props.params.statePostal];
            let isPartiallyDeployed = (deploymentStatus === "partdeployed" )
            let isPartial = isPartiallyDeployed
            if(deploymentStatus === "deployed") {
                className = classes.deployedState;
            } else if(isPartiallyDeployed) {
                className = classes.partialState;
            } else if(vine3Config.default[this.props.params.statePostal] === "nocontract") {
                className = classes.nocontractState;
            }
            return isSelected ?
                <path
                    key={countyFeature.properties.GEO_ID}
                    fill={
                        !isPartial
                        ? ""
                        : "url(#deployHatch)"
                    }
                    className={className}
                    d={path(countyFeature.geometry)}
                    onMouseMove={this._onMouseMove.bind(this)}
                    onMouseEnter={() => {
                        this.setState({mouseOverCounty: true, countyMousedOverName: countyFeature.properties.NAME.toLowerCase()})
                    }}
                    onClick={() => {
                        this.setState({mouseOverCounty: true, countyMousedOverName: countyFeature.properties.NAME.toLowerCase()})
                    }}
                    onMouseLeave={() => {
                        this.setState({ mouseOverCounty: false, countyMousedOverName: ''})
                    }}
                    style={{strokeWidth: ""+1/this.mainK}}
                    transform={`translate(${this.state.width/2}, ${this.state.height/2})
                                scale(${this.mainK})
                                translate(${-this.mainX}, ${-this.mainY})`}
                /> : null;
        })

        // Generate an info card for the current county if needed
        let countyCard = null;
        if(this.state.mouseOverCounty) {
            let notifications = 0;
            if(this.state.countyNotificationTotals) {
                notifications = this.state.countyNotificationTotals[''+this.state.countyMousedOverName].current;
            }
            let registrations = 0;
            if(this.state.countyRegistrationTotals) {
                registrations = this.state.countyRegistrationTotals[''+this.state.countyMousedOverName].current;
            }
            countyCard = <StateCard
                            stateName={this.state.countyMousedOverName.charAt(0).toUpperCase()+this.state.countyMousedOverName.substr(1)}
                            top={this.state.mouseY+20+"px"}
                            left={this.state.mouseX-100+"px"}
                            notificationCount={notifications}
                            registrationCount={registrations}/>;
        }
        const {navigate} = this.props;
        // Generate legend if screen width is over 1000 pixels
        const legend = (window.innerWidth >= 1000) ? this.generateLegendComponents([window.innerWidth-285, window.innerHeight-145]) : null;

        // Generate Mobile Key if screen width is less than 1000 pixels
        const mobileKey = (window.innerWidth < 1000) ? <MobileKey /> : null;
        // Render the svg canvas and states
        return (
            <div>
                <div className={classes.StateMap}>
                    <svg style={{position: 'fixed', top: '0', left: '0'}} width={width} height={height} onClick={() => navigate('/')}>
                        <g id="states">
                            {states}
                            {legend}
                        </g>
                    </svg>
                    <svg style={{position: 'fixed', top: '0', left: '0', pointerEvents: 'none'}} width={width} height={height}>
                    <pattern id="deployHatch" width="10" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
                            <line x1="0" y1="0" x2="0" y2="10" style={{stroke:'#652f6c', strokeWidth:11}} />
                        </pattern>
                        <g id="counties">
                            {counties}
                        </g>
                        {this.state.blips}
                    </svg>
                    <ActivityDisplay notifications={this.state.notificationCount} registrations={this.state.registrationCount} />
                    {mobileKey}
                    {countyCard}
                    <Ribbon />
                </div>
            </div>
        );
    }
    scrolled(e) {
        let transform = e.transform;
        let transformString = `translate(${this.state.width/2}, ${this.state.height/2})
        scale(${transform.k})
        translate(${-this.state.centroid[0]}, ${-this.state.centroid[1]})`;
        d3.select("#states")
        .selectAll('path') // To prevent stroke width from scaling
        .attr('transform', transformString);
        d3.select("#counties")
        .selectAll('path') // To prevent stroke width from scaling
        .attr('transform', transformString);
    }
}

export default withParams(StateMap);