import React from 'react';
import classes from './Dashboard.module.css';
/* Components */
import Masthead from '../../components/Masthead/Masthead';
import Ribbon from '../../components/Ribbon/Ribbon';
/* Containers */
import Map from '../../containers/Map/Map';
import {useParams} from "react-router-dom";

function Dashboard() {
        let params = useParams()
        return(
            <div className={classes.Dashboard}>
                <Ribbon />
                {params.profile === 'appriss' ? null : (<Masthead />)}
                <Map />
            </div>
        );
}

export default Dashboard;