import React from 'react';
import classes from './ActivityDisplay.module.css';

const activityDisplay = (props) => {
    return (
        <div className={'fixed-bottom '+classes.ActivityDisplay}>
            <div className={props.isMini ? 'row align-items-center justify-content-center' : 'row-override align-items-center justify-content-center'}>
                <div className='col-sm-12 col-lg-auto text-center text-lg-right'>
                    <p className={'larger-text '+classes.Text}>Today's activity:</p>
                </div>
                <div className='col-sm-12 col-lg-auto text-center'>
                    <p className={'larger-text '+classes.Count}>{props.registrations || 'Loading...'}</p>
                    <p className={'larger-text '+classes.Text}>Registrations</p>
                </div>
                <div className='col-sm-12 col-lg-auto text-center text-lg-left'>
                    <p className={'larger-text '+classes.Count}>{props.notifications || 'Loading...'}</p>
                    <p className={'larger-text '+classes.Text}>Notifications</p>
                </div>
            </div>
        </div>
    );
}

export default activityDisplay;