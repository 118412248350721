import React, {Component} from 'react';
import classes from './Map.module.css';
/* Configuration Files */
import * as vine3Config from '../../assets/vine3Config.json';
/* D3 */
import * as d3 from 'd3';
import * as d3Projections from 'd3-composite-projections';
import statesGeoJson from '../../assets/us-states-lo.json';
/* Routing */
import {Link} from 'react-router-dom';
/* WebSocket */
import io from 'socket.io-client';
/* Components */
import ActivityDisplay from '../../components/ActivityDisplay/ActivityDisplay';
import Blip from '../../components/Blip/Blip';
import MobileKey from '../../components/MobileKey/MobileKey';
import StateCard from '../../components/StateCard/StateCard';



// import { filter } from 'lodash';
//import { concat } from 'lodash';

class Map extends Component {

/** STATE */
constructor(props) {
    super(props);
    this.rescale = this.rescale.bind(this);
    this.scrolled = this.scrolled.bind(this);
    //this.filterNotificationType = new FilterNotificationType;
    
}

state = {
    // Precalculated Presentation Functions and Values
    projection: null,
    path: null,
    scCentroid: null,  // Reference point for legend placement
    // Presentation Preferences
    projectionScale: 0.9,
    projectionScaleMobile: 0.6,
    projectionScaleMini: 1.0,
    // Prerendered Presentation Components
    statePaths: [],
    // Mouseover Functionality
    mouseX: 0,
    mouseY: 0,
    isMousedOverState: false,
    stateMousedOverName: '',
    stateMousedOverPostal: '',
    stateNotificationTotals: '',
    stateRegistrationTotals: '',
    // Notification and Registration state
    blips: [],
    notificationCount: null,
    registrationCount: null,
    
}

/** HELPER METHODS */

    /*
        inputs:
            isMousedOverState: Boolean True (if a state is moused over) or False (if a state is not moused over)
            stateMousedOverName: String name of the state currently moused over
            stateMousedOverPostal: Postal code of the state currently moused over
        outputs: none
        description:
            Takes in whether a state is currently moused over, a name, and a postal code and
            updates the container's state to match the provided values
    */
    updateMouseoverState = (isMousedOverState, stateMousedOverName, stateMousedOverPostal) => {
        this.setState({
            isMousedOverState: isMousedOverState,
            stateMousedOverName: stateMousedOverName,
            stateMousedOverPostal: stateMousedOverPostal
        });
    }

    /*
        inputs:
            event: A window event
        outputs: none
        description:
            Takes in a window event, grabs the mouse's X,Y coords on
            the page, and updates the container's state to those values
    */
    onMouseMove = (event) => {
        this.setState({
            mouseX: event.pageX,
            mouseY: event.pageY
        });
    }

    /*
        inputs: none
        outputs:
            A StateCard JSX component which can be rendered to the screen
        description:
            Uses the container's state to generate an info-card for a currently
            hovered-over state.
    */
    generateStateInfoCard = () => {
        // Get the number of notifications for the currently hovered-over state
        let notifications = (this.state.stateNotificationTotals) ? 
            this.state.stateNotificationTotals[''+this.state.stateMousedOverPostal].current
            : 'Loading...';
        // Get the number of registrations for the currently hovered-over state
        let registrations = (this.state.stateRegistrationTotals) ?
            this.state.stateRegistrationTotals[''+this.state.stateMousedOverPostal].current
            : 'Loading...';
        // Generate the state info card component and return it
        return (
            <StateCard
                stateName={this.state.stateMousedOverName}
                top={this.state.mouseY+20+'px'}
                left={this.state.mouseX-100+'px'}
                notificationCount={notifications}
                registrationCount={registrations}
            />
        );
    }

    /*
        inputs: none
        outputs: nonw
        description:
            Increases the container's state's notificationCount by one when called
    */
    incrementNotificationCount = () => {
        this.setState({notificationCount: this.state.notificationCount+1});
    }

    /*
        inputs: none
        outputs: none
        description:
            Increases the container's state's registrationCount by one when called
    */
    incrementRegistrationCount = () => {
        this.setState({registrationCount: this.state.registrationCount+1});
    }
    /*
        inputs: none
        outputs: none
        description:
            Creates projection and path functions then stores them in state.
            Creates new state path svg jsx elements and stores them in state.
            Calculates South Carolina's centroid and stores it in state (for use when placing legend on map).
            Call whenever the size of the map changes and any values which only need to be recalculated once
            will be recalculated and stored into the container's state.
    */
    rescale = () => {
        // Create new projection and path functions
        const canvasWidth = window.innerWidth;
        const canvasHeight = window.innerHeight;
        let scale = this.state.projectionScale;
        // handle mobile and miniature scaling
        if(window.innerWidth < 1000 & window.innerWidth > 500) {
            scale = this.state.projectionScaleMobile;
        } else if(window.innerWidth < 500) {
            scale = this.state.projectionScaleMini;
        }
        const newProjection = d3Projections.geoAlbersUsaTerritories().translate([canvasWidth/2, canvasHeight/2]).scale([canvasWidth*scale]);
        const newPath = d3.geoPath().projection(newProjection);
        // Calculate new state paths
        const newStatePaths = this.generateStatePaths(newPath);
        // Calculate new centroid of South Carolina
        const newSCCentroid = this.generateStateCentroid(newPath, 'sc');
        // Update state paths and d3 functions        
        this.setState({
            projection: newProjection,
            path: newPath,
            scCentroid: newSCCentroid,
            statePaths: newStatePaths
        });         
    }

    /*
        inputs:
            pathFunction: A d3 path function for use when converting a features geometry into svg d
        outputs:
            statePaths: An array of SVG <path .../> elements.  One for each state, ready to display.
        description:
            Given a path function, converts state GeoJson into usable svg <path .../> elements at the
            correct size for the screen size used in creating the path function.  Adds in hoverover
            functionality. Does not modify container state.
    */
    generateStatePaths = (pathFunction) => {
        // Convert state GeoJSON to svg <path .../> elements using provided d3 path function
        const statePaths = statesGeoJson.features.map(stateFeature => {
            let className = null;
            let state = stateFeature.properties.postal;
            //^two letter abreviation, some like dc are not states but close enough
            let deploymentStatus = vine3Config.default[state];
            let isPartiallyDeployed = (deploymentStatus === "partdeployed" )
            let isPartiallyContracted = (deploymentStatus === "partcontracted")
            let isPartial = (isPartiallyContracted || isPartiallyDeployed)
            if(deploymentStatus === "deployed") {
                className = classes.deployedState;
            } else if(isPartiallyContracted || isPartiallyDeployed) {
                className = classes.partialState;
            } else if(deploymentStatus === "contracted") {
                className = classes.contractedState;
            } else if(vine3Config.default[state] === "nocontract") {
                className = classes.nocontractState;
            }
            // we need special logic for Puerto Rico
            let transformVar = null;
            // PR placeholder, don't display it yet
            if(stateFeature.properties.postal === 'pr') {
                return null;
            }
            return (
                <Link 
                    to={{pathname:'/'+stateFeature.properties.postal, search:window.location.search}}
                    key={stateFeature.properties.name}
                >
                    <path
                        id={stateFeature.properties.postal}
                        className={className}
                        fill={
                            !isPartial 
                            ? ""
                            : isPartiallyContracted
                            ? "url(#contractHatch)" 
                            : "url(#deployHatch)" 
                        }
                        d={pathFunction(stateFeature.geometry)}
                        transform={transformVar}
                        onMouseMove={this.onMouseMove.bind(this)}
                        onMouseEnter={() => this.updateMouseoverState(true, stateFeature.properties.name, stateFeature.properties.postal)}
                        onMouseLeave={() => this.updateMouseoverState(false, '', '')}
                    />
                </Link>
            );
        });
        // Return the array of <path .../> elements
        return statePaths;
    }

    /*
        inputs:
            pathFunction: A d3 path function for use when calculating a GeoJson's geomerty w.r.t. current screen dimensions
        outputs:
            southCarolinaCentroid: A two element array containing the X and Y components of South Carolina's centroid
        description:
            Given a d3 path function, searches through state GeoJson looking for South Carolina.  When South Carolina
            is found, uses the provided path function to calculate its centroid w.r.t. current screen width and height.
            This centroid is later used to position the Legend on the map w.r.t. a state (SC).
            Returns the centroid array, does not modify container state.
    */
    generateStateCentroid = (pathFunction, state) => {
        let stateCentroid = null;
        // Find South Carolina's GeoJson
        statesGeoJson.features.forEach(feature => {
            // Calculate South Caronlina's centroid using the provided path function
            if (feature.properties.postal === state) {
                stateCentroid = pathFunction.centroid(feature);
            }
        });
        // Return South Caronlina's centroid
        return stateCentroid;
    }

    /*
        input:
            southCaronlinaCentroid: South Carolina's centroid [X,Y] w.r.t. current screen dimensions
        output:
            An array of SVG JSX elements to be rendered.  Assumes they will be enclosed later within an <svg></svg> tag.
        description:
            Takes in South Carolina's centroid.  Then creates the necessary <rect /> and <text /> elements to make
            a legend on a SVG canvas.  Legend will be shifted to the right of South Carolina. Returns an array
            of svg jsx elements ready to be drawn.
    */
    generateLegendComponents = (southCarolinaCentroid) => {
        // Set size and placement configurations
        const x = southCarolinaCentroid[0]+100;
        const y = southCarolinaCentroid[1]-10;
        const offsetX = 10; // Shift in X direction
        const offsetY = 25; // Multiplier in the Y direction
        const offsetTextX = 23; // Shift in X direction
        const offsetTextY = 12; // Shift in Y direction
        const iconHight = 20;
        //const stripeHeight = iconHight/3;
        //const stripePosistion =((iconHight/2) - stripeHeight/2);//the stripe is always in center of icon
        // Create the outer box
        let legendBox = <rect key='legendbox' className={classes.Legend} x={x} y={y+10} 
            width='210' height='120' rx='15' ry='15' />;
        // Create the deployed icon components
        let deployedSymbol = <rect key='deploysym' className={classes.deployedState} 
            x={x+offsetX} y={y+offsetY} width='20' height={iconHight} />;
        let deployedText = <text key='deploytxt' x={x+offsetX+offsetTextX} y={y+offsetY+offsetTextY} 
            dominantBaseline='middle'>VINE Statewide</text>;
        // Create the partdeployed components
        let partdeployedSymbol = <rect key='partdeploysym' fill="url(#deployHatch)"
            className={classes.partialState} 
            x={x+offsetX} y={y+2*offsetY} width='20' height={iconHight} />;
        //let partdeployedStripe = <rect key='partdeploystripe' className={classes.deployedState} 
        //    x={x+offsetX} y={y+2*offsetY+stripePosistion} width='20' height={stripeHeight} />;
        let partdeployedText = <text key='partdeploytxt' x={x+offsetX+offsetTextX} y={y+2*offsetY+offsetTextY} 
            dominantBaseline='middle'>VINE in Some Counties</text>;
        // Create the contracted components
        /*let contractedSymbol = <rect key='contractsym' className={classes.contractedState} 
            x={x+offsetX} y={y+3*offsetY} width='20' height={iconHight} />;
        let contractedText = <text key='contracttxt' x={x+offsetX+offsetTextX} y={y+3*offsetY+offsetTextY} 
            dominantBaseline='middle'>Contracted</text>;*/
        // Create the partcontracted components
        /*let partcontractedSymbol = <rect key='partcontractsym' fill="url(#contractHatch)"
            className={classes.partialState} 
            x={x+offsetX} y={y+4*offsetY} width='20' height={iconHight} />;
        //let partcontractedStripe = <rect key='partcontractstripe' className={classes.contractedState} 
        //    x={x+offsetX} y={y+4*offsetY+stripePosistion} width='20' height={stripeHeight} />;
        let partcontractedText = <text key='partcontracttxt' x={x+offsetX+offsetTextX} y={y+4*offsetY+offsetTextY} 
            dominantBaseline='middle'>Contracted in some areas</text>;*/
        // Create the Disabled components
        /*let disabledSymbol = <rect key='disabledsym' className={classes.nocontractState} 
            x={x+offsetX} y={y+5*offsetY} width='20' height={iconHight} />;
        let disabledText = <text key='disabledtxt' x={x+offsetX+offsetTextX} y={y+5*offsetY+offsetTextY} 
            dominantBaseline='middle'>Pending Contract</text>;*/
        
        // Create the Registration components
        let registrationSymbol = <circle key='regsym' className={classes.LegendRegistrationSymbol} 
            cx={x+offsetX+10} cy={y+3*offsetY+10} r='9' />;
        let registrationText = <text key='regtxt' x={x+offsetX+offsetTextX} y={y+3*offsetY+offsetTextY} 
            dominantBaseline='middle'>Registration</text>
        
        // Create the Notification components
        let notificationSymbol = <circle key='notsym' className={classes.LegendNotificationSymbol} 
            cx={x+offsetX+10} cy={y+4*offsetY+10} r='9' />;
        let notificationText = <text key='nottxt' x={x+offsetX+offsetTextX} y={y+4*offsetY+offsetTextY} 
            dominantBaseline='middle'>Notification</text>;
        // Combine into a render-able array and return
        return [legendBox, deployedSymbol, deployedText, partdeployedSymbol, partdeployedText,
            /*contractedSymbol,contractedText,partcontractedSymbol, partcontractedText, disabledSymbol, disabledText, */
            registrationSymbol, registrationText, notificationSymbol, notificationText];
    }

    /*
        input:
            message: an un-parsed json message string containing info on current events and totals
        output: none
        description:
            Receives an un-parsed message string.  Parses it into a JSON object.
            Generates notification and registration blips.  Update's the container's
            blips and notification/registration total and state total counts.
    */
    handleEventUpdate = (message) => {
        // Parse the incoming message data into a JSON object
        const messageJson = JSON.parse(message);
        // Generate the Notification blips
        const notificationBlips = messageJson.notifications.events.map(event => {

            return <Blip
                        key={event.sid}
                        //method={event.method}
                        x={this.state.projection([+event.longitude, +event.latitude])[0]}
                        y={this.state.projection([+event.longitude, +event.latitude])[1]}
                        isNotification={true}
                        incrementer={this.incrementNotificationCount}
                        canvasWidth={window.innerWidth}
                    />;
        });
        // Generate the Registration blips
        const registrationBlips = messageJson.registrations.events.map(event => {

            return <Blip
                        key={event.sid}
                        x={this.state.projection([+event.longitude, +event.latitude])[0]}
                        y={this.state.projection([+event.longitude, +event.latitude])[1]}
                        isNotification={false}
                        incrementer={this.incrementRegistrationCount}
                        canvasWidth={window.innerWidth}
                    />;                
        });
        // Update the state to hold the new blips and updated counts
        this.setState({
            blips: notificationBlips.concat(registrationBlips),
            notificationCount: messageJson.notifications.totals.last,
            registrationCount: messageJson.registrations.totals.last,
            stateNotificationTotals: messageJson.notifications.totals,
            stateRegistrationTotals: messageJson.registrations.totals
        });
    }

/** LIFECYCLE METHODS */

    componentDidMount() {
        // Call rescale when the window resizes
        window.addEventListener('resize', this.rescale);
        // Call rescale initially
        this.rescale();
        // Call zoom
        const zoom = d3.zoom()
            .scaleExtent([1, 8])
            .on('zoom', this.scrolled);
        d3.selectAll('svg').call(zoom);
        // Connect to the main websocket
        const socket = io(process.env.REACT_APP_EVENTS_URL+'?key=all', { withCredentials: true });
        // Have the websocket pass the message for all 'events_all'
        // messages into the handleEventUpdate method
        socket.on('events_all', msg => this.handleEventUpdate(msg));
    }
    componentWillUnmount() {
        // Quit listening to window resizes
        window.removeEventListener('resize', this.rescale);
    }
    render() {
        // Check the size of the window
        let isMobile = false;
        let isMini = false;
        if(window.innerWidth <= 1000 && window.innerWidth > 500) {
            isMobile = true;
        } else if(window.innerWidth <= 500) {
            isMini = true;
        }
        // Generate the map's legend IF we have South Carolina's centroid to place it with AND the window is large enough to reasonably fit it
        const legend = (this.state.scCentroid && !isMobile && !isMini) ? this.generateLegendComponents(this.state.scCentroid) : null;

        // Generate map's mobile key legend if window is smaller than 1000 pixels
        const mobileKey = (isMobile || isMini) ? <MobileKey /> : null;

        // Generate the info card IF a state is moused over
        let stateInfoCard = (this.state.isMousedOverState) ? this.generateStateInfoCard() : null;
        
        return (
            <div>
                <div className={classes.MapContainer}>
                    <svg className={classes.Canvas} style={{height: window.innerHeight}}>
                        <g id="map">
                            <pattern id="deployHatch" width="10" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
                                <line x1="0" y1="0" x2="0" y2="10" style={{stroke:'#652f6c', strokeWidth:11}} />
                            </pattern>
                            <pattern id="contractHatch" width="10" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
                                <line x1="0" y1="0" x2="0" y2="10" style={{stroke:'#007298', strokeWidth:11}} />
                            </pattern>
                            <rect className={classes.CanvasFiller} x='0' y='0' width={window.innerWidth} height={window.innerHeight} />
                            {this.state.statePaths}
                            {legend}
                            {this.state.blips}
                        </g>
                    </svg>
                </div>
                <ActivityDisplay isMini={isMini} registrations={this.state.registrationCount} notifications={this.state.notificationCount} />
                {mobileKey}
                {stateInfoCard}
            </div>
        );
    }

    scrolled(e) {
        d3.select("#map")
        .selectAll('path') // To prevent stroke width from scaling
        .attr('transform', e.transform);
    }
}

export default Map;