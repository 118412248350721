import React, { Component } from 'react';
import classes from './App.css';
/* Router */
import { Route, Routes, Navigate } from 'react-router-dom';
/* Containers and Components */
import Dashboard from './containers/Dashboard/Dashboard';
import StateMap from './components/StateMap/StateMap';


class App extends Component {
	render() {
		return (
			<div className="container-fluid" style={{paddingLeft: '0px', paddingRight: '0px'}}>
				<div className={classes.App}>
					<Routes>
						{/* <Route path="/auth" component={Auth} /> */}
						<Route path="/" exact element={ <Dashboard />} />
						<Route path="/:statePostal" exact element={<StateMap/>} />
						<Route path="*" render={() => <Navigate to="/" />} />
					</Routes>
				</div>
			</div>
    	);
	}
}

export default App;
