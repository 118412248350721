import React, { Component } from 'react';
import classes from './Ribbon.module.css';

class Ribbon extends Component {
    render() {
        let environment = process.env.REACT_APP_ENVIRONMENT;
        return (environment === 'production') ? null : 
            <div
                className={classes.Ribbon}
                style={{
                    background: "#e82"
                }}
            >{environment}</div>;
    }
}

export default Ribbon;