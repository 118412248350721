import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
/* Redux */
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import authReducer from './store/reducers/authReducer';
/* Router */
import { BrowserRouter } from 'react-router-dom';

// Combine all reducers into a single root reducer
const rootReducer = combineReducers({
    auth: authReducer
});

// Create a redux store using the root reducer
const store = createStore(rootReducer);

// Connect the redux store to our app
// and add routing
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

const rootElement =
document.getElementById('root');
const root =
createRoot(rootElement);

root.render(app,
);
registerServiceWorker();