import React, { Component } from 'react';
import classes from './Blip.module.css';
import * as d3 from 'd3';
// import { method } from 'lodash';

class Blip extends Component {
    
    state = {
        reference: React.createRef()
    }

    componentDidMount() {
        const blipRadiusModifier = (this.props.stateConstant) ? 15*this.props.stateConstant : 15;

        // Hand off the dom reference to d3
        // Create the "blipping" effect
        const delay = Math.floor(Math.random()*9000);
        setTimeout(() => {
            this.props.incrementer()
        }, delay);
        d3.select(this.state.reference.current)
            .transition()
            .delay(d => delay)
            .duration(1000)
            .attr('r', ''+this.props.canvasWidth/blipRadiusModifier)
            .attr('opacity', '0');
    }

    render() {
        let transform = null;
        if(this.props.transform) {
            transform = `translate(${this.props.transform.translateX1}, ${this.props.transform.translateY1})
            scale(${this.props.transform.scale})
            translate(${this.props.transform.translateX2}, ${this.props.transform.translateY2})`
        }
        let strokeWidth = (this.props.customStrokeWidth) ? this.props.customStrokeWidth : 2;
        return (
            <circle
                className={classes.Blip}
                ref={this.state.reference}
                cx={this.props.x}
                cy={this.props.y}
                fill={this.props.isNotification ? '#c83144' : 'white'}
                r="0"
                opacity="1"
                strokeWidth={strokeWidth}
                transform={transform}
            />
        );
    }
}

export default Blip;